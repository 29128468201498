import React, { useState, useContext, useEffect } from "react";

import TenantContext from "../../../utils/tenantContext";

const Footer = () => {
  let date = new Date();

  const {tenant} = useContext(TenantContext);
  const [privacyPolicyUrl, privacyPolicyUrlSET] = useState("");
  const [termsAndConditionsUrl, termsAndConditionsUrlSET] = useState("");

  useEffect(() => {
		if(tenant) {
			privacyPolicyUrlSET(tenant.privacy_policy_url);
      termsAndConditionsUrlSET(tenant.terms_and_conditions_url);
		}
	}, [tenant]);

  return (
    <footer className="footer text-left">
      <strong>
        Copyright © {date.getFullYear()} &nbsp;
        <a
          href="https://zakimusic.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zaki Music&nbsp;
        </a>
      </strong>
      <span>All rights reserved.</span> |
      <a style={{padding: '0 0 0 4px'}} href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a> |
      <a style={{padding: '0 0 0 4px'}} href={termsAndConditionsUrl} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
    </footer>
  );
};

export default Footer;